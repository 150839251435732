import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SharedLibs from 'wizarding-world-web-shared';
import { useQuery } from '@apollo/client';
import { AGE_GATE_IS_UNDERAGE } from '@constants/index';
import { setAuthSuccessRedirect } from '@utils/authSuccessRedirect';
import { STORE_URL } from '@utils/graphQueries';
import Loaders from '../Loaders';
import loaderTypes from '../Loaders/loaderTypes';
import { useUserContext } from '../../contexts/user-context';
import { HOME_URL, LOGIN, REGISTER, YOUNG_VISITORS } from '../../constants/routes';
import s from './StoreRedirect.module.scss';

const propTypes = {
  query: PropTypes.shape({
    path: PropTypes.string,
    passthrough: PropTypes.string,
    register: PropTypes.string,
    store: PropTypes.string,
  }),
};

const StoreRedirect = ({ query = {} }) => {
  const {
    path = HOME_URL,
    passthrough = false,
    register = false,
    store,
    ...additionalParams
  } = query;
  const { getCookie } = SharedLibs.utils.cookie;
  const ecomAgeGateEnabled = process.env.REACT_APP_ECOM_AGE_GATE_ENABLED === 'TRUE';
  const passThrough = passthrough === 'true';
  const registerUser = register === 'true';
  const queryString = Object.keys(additionalParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(additionalParams[key])}`)
    .join('&');
  const { loading, isLoggedIn, profile, loadingProfile } = useUserContext();
  const makeMultipassCall = !loading && (passThrough || isLoggedIn);
  const { loading: storeLoading, data } = useQuery(STORE_URL, {
    variables: {
      path: `${path}${queryString ? `?${queryString}` : ''}`,
      store,
    },
    ssr: false,
    skip: !makeMultipassCall,
  });

  useEffect(() => {
    if (!loading && !loadingProfile) {
      if (!passThrough && !isLoggedIn) {
        setAuthSuccessRedirect(`${window.location.pathname}${window.location.search}`);

        // Include utm params in redirect to login/register to be saved as cookies
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.forEach((_, key) => {
          if (!key.startsWith('utm_')) searchParams.delete(key);
        });
        const utmParams = searchParams.toString();

        window.location.replace(
          `${registerUser ? REGISTER : LOGIN}${utmParams ? `?${utmParams}` : ''}`,
        );
      } else if (!storeLoading) {
        if (data) {
          const url = data.storeUrl?.url ?? null;
          if (url) {
            const ageGateIsUnderage = getCookie(AGE_GATE_IS_UNDERAGE) === 'true';
            const anonUnderage = ecomAgeGateEnabled && !isLoggedIn && ageGateIsUnderage;
            const isUnderAge = profile?.underage ?? true;
            if ((isUnderAge && isLoggedIn) || anonUnderage) {
              window.location.replace(YOUNG_VISITORS);
            } else {
              window.location.replace(url);
            }
          }
        }
      }
    }
  }, [data, isLoggedIn, loading, passThrough, storeLoading, registerUser, profile, loadingProfile]);
  return (
    <div className={s.root}>
      <Loaders type={loaderTypes.timeturner} />
    </div>
  );
};

StoreRedirect.propTypes = propTypes;
export default StoreRedirect;
