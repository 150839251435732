
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from 'react';
import PropTypes from 'prop-types';
import StoreRedirect from '../../src/components/StoreRedirect';
const propTypes = {
    query: PropTypes.shape({})
};
const Store = ({ query = {} }) => <StoreRedirect query={query}/>;
export const getInitialProps = async (context) => {
    return { query: context.query };
};
Store.getInitialProps = getInitialProps;
Store.propTypes = propTypes;
const __Next_Translate__Page__194cc794cfa__ = Store;

    export default __appWithI18n(__Next_Translate__Page__194cc794cfa__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  